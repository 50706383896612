<template>
  <div class="available-in-holder" data-testid="available-in-holder">
    <div
      :id="id"
      class="select-holder"
      tabindex="0"
      data-testid="available-in-select-holder"
      @click="openModal"
    >
      <el-tag v-if="isNowhere" class="location-tag" size="small">
        {{ $t('components.available_in.nowhere') }}
      </el-tag>
      <el-tag v-if="isEverywhere" class="location-tag" size="small">
        {{ $t('components.available_in.everywhere') }}
      </el-tag>
      <el-tag
        v-for="location in locationChips"
        v-else
        :key="location.id"
        class="location-tag"
        size="small"
      >
        {{ location.name }}
      </el-tag>
    </div>

    <th-modal
      :name="modalName"
      width="1200px"
      height="800px"
      :title="$t('components.available_in.label')"
      body-class="flex flex-col"
      data-testid="available-in-modal"
      @close="cancelModal"
    >
      <tabs v-model="activeTab" class="el-tabs-full">
        <tab
          :label="$t('components.available_in.locations.label')"
          name="locations"
          :active="activeTab === 'locations'"
        >
          <multi-location-select v-model="localLocations" />
        </tab>
        <tab
          v-if="hideBranchGroups === false"
          :label="$t('components.available_in.branch_groups.label')"
          name="branchGroups"
          :active="activeTab === 'branchGroups'"
        >
          <group-items-select
            v-model="localBranchGroups"
            resource="branchGroups"
            show-search-filter
            :filters="[]"
            :normalise-keys="branchGroupsNormaliseKeys"
            :title="$t('components.available_in.branch_groups.label')"
          />
        </tab>
      </tabs>

      <div class="flex justify-end shadow-md p-4 bg-th-light-blue">
        <!-- Make available everywhere -->
        <el-button
          v-if="!hideAvailableEverywhere"
          id="available-everywhere"
          @click="makeAvailableEverywhere"
        >
          {{ $t('components.available_in.make_available_everywhere') }}
        </el-button>

        <!-- Make available nowhere -->
        <el-button
          v-if="!hideAvailableNowhere"
          id="available-nowhere"
          @click="makeAvailableNowhere"
        >
          {{ $t('components.available_in.make_available_nowhere') }}
        </el-button>
      </div>

      <template #footer>
        <el-button @click="cancelModal">
          {{ $t('common.interactions.buttons.cancel') }}
        </el-button>
        <el-button type="primary" @click="saveModal">
          {{ $t('common.interactions.buttons.confirm') }}
        </el-button>
      </template>
    </th-modal>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import GroupItemsSelect from '@/components/group-items-select'
import MultiLocationSelect from '@/components/select/multi-location-select'
import Tabs from '@/components/tabs'
import Tab from '@/components/tabs/tab'
const isEmptyArray = (value) => Array.isArray(value) && !value.length

export default {
  name: 'AvailableIn',

  components: {
    GroupItemsSelect,
    MultiLocationSelect,
    Tabs,
    Tab
  },

  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => ({
        locations: undefined,
        branch_groups: undefined
      })
    },
    id: {
      type: String,
      default: undefined
    },
    resources: {
      type: Object,
      required: true
    },
    showItemsLimit: {
      type: Number,
      default: undefined
    },
    hideBranchGroups: {
      type: Boolean,
      default: false
    },
    hideAvailableNowhere: {
      type: Boolean,
      default: false
    },
    hideAvailableEverywhere: {
      type: Boolean,
      default: false
    },
    sendToId: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      branchGroupsFiltersList: [],
      activeTab: 'locations',
      availableEverywhere: {
        locations: null,
        branch_groups: null
      },
      availableNowhere: {
        locations: [],
        branch_groups: []
      },
      localBranchGroups: [],
      localLocations: []
    }
  },

  computed: {
    isNowhere() {
      if (this.hideBranchGroups) {
        return isEmptyArray(this.modelValue.locations)
      }

      return (
        isEmptyArray(this.modelValue.locations) &&
        isEmptyArray(this.modelValue.branch_groups)
      )
    },
    isEverywhere() {
      if (this.hideBranchGroups) {
        return this.modelValue.locations === null
      }

      return (
        this.modelValue.locations === null &&
        this.modelValue.branch_groups === null
      )
    },
    locationChips() {
      if (!this.modelValue.locations && !this.modelValue.branch_groups)
        return []
      const items = [
        ...(this.modelValue.locations || []),
        ...(this.modelValue.branch_groups || [])
      ]

      const branches = safeGet(this.resources, 'branches') || []
      const branchGroups = safeGet(this.resources, 'branchGroups') || []

      const itemsToDisplay = this.showItemsLimit
        ? items.slice(0, this.showItemsLimit)
        : items

      const chips = itemsToDisplay
        .map((item) => {
          const location =
            branchGroups.find((group) => item === group.id) ||
            branches.find((branch) => item === branch.id)

          if (!location) return undefined

          return {
            id: item,
            name: location?.name
          }
        })
        .filter(Boolean)

      const restNumber = this.showItemsLimit
        ? items.length - this.showItemsLimit
        : 0

      const restElement =
        restNumber > 0
          ? [{ name: `+ ${restNumber}`, id: 'rest-element-id-1' }]
          : []

      return this.showItemsLimit ? [...chips, ...restElement] : chips
    },
    modalName() {
      return this.sendToId ? this.id : 'available-in'
    }
  },

  methods: {
    branchGroupsNormaliseKeys(branchGroup) {
      return {
        ...branchGroup,
        title_main: branchGroup.name
      }
    },
    makeAvailableEverywhere() {
      this.localBranchGroups = null
      this.localLocations = null
      this.saveModal()
    },
    makeAvailableNowhere() {
      this.localBranchGroups = []
      this.localLocations = []
      this.saveModal()
    },
    openModal() {
      this.localBranchGroups = this.modelValue.branch_groups
      this.localLocations = this.modelValue.locations
      this.$thModal.show(this.modalName)
    },
    cancelModal() {
      this.$thModal.hide(this.modalName)
    },
    saveModal() {
      const emitName = this.sendToId ? this.id : 'update:modelValue'
      this.$emit(emitName, {
        branch_groups: this.localBranchGroups,
        locations: this.localLocations
      })
      this.$thModal.hide(this.modalName)
    }
  }
}
</script>

<style scoped>
.available-in-holder {
  width: 100%;
}

.select-holder {
  width: 100%;
  -webkit-appearance: none;
  background-color: #fff;
  border-radius: 4px;
  border: var(--select-double-input-border);
  box-shadow: var(--select-box-shadow);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--label-text-color);
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 7px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  overflow-x: auto;
}

.select-holder:hover {
  box-shadow: inset 0 0 0 1px var(--border-color-hover);
}

.location-tag {
  margin-right: 0.5rem;
}
.el-tabs-full :deep(.th-tabs-body) {
  flex-grow: 1;
  overflow: auto;
}
.el-tabs-full :deep(.th-tab) {
  height: 100%;
}
</style>
